import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Icon from "components/elements/Icon"
import "./links.scss"
import BhcoeIcon from "./BhcoeAccredidation.webp"

const Links = ({ utilityLinks }) => {
  const year = new Date().getFullYear()

  return (
    <>
      <div className="g-footer-copyright-links">
        <p>&copy; {year} Cortica</p>
        {utilityLinks.map((link, i) => (
          <Link key={i} to={link.path}>
            {link.text}
          </Link>
        ))}
      </div>
      <div className="g-footer-lgbt-icon">
        <img alt="bhcoe logo" src={BhcoeIcon} />
        <Icon icon="Lgbtq" />
      </div>
    </>
  )
}

Links.props = {
  utilityLinks: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      text: PropTypes.string,
    })
  ),
}

Links.propTypes = Links.props

export default Links
